import React from "react";
import logo from "../../Pictures/logo.png";
import styles from "./Footer.module.css";
import { Icon } from "@iconify/react";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div>
      <footer className={`container-fluid text-center ${styles.footer}`}>
        <div className="row">
          {/* image column */}
          <div className={`col-md-3 col-sm-6 mt-4 ${styles.Imagediv}`}>
            <img
              src={logo}
              alt="company-logo"
              height={"120px"}
              className={styles.footerimg}
            />
          </div>
          {/* company */}
          <div className="col-md-3 col-sm-6 text-color-white">
            <h6 className={styles.heading}>Company</h6>
            <ul className={styles.list}>
              <li className={styles.listItems}>
                <a className="text-white text-decoration-none" href="/home">
                  Home
                </a>
              </li>

              <li className={styles.listItems}>
                <a className="text-white text-decoration-none" href="/contact">
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
          {/* resources */}
          <div className="col-md-3 col-sm-6">
            <h6 className={styles.heading}>Resources</h6>
            <ul className={styles.list}>
              <li className={styles.listItems}>
                <a className="text-white text-decoration-none" href="/employer">
                  Employer
                </a>
              </li>
              <li className={styles.listItems}>
                <a className="text-white text-decoration-none" href="/employee">
                  Employee
                </a>
              </li>
              <li className={styles.listItems}>
                <a className="text-white text-decoration-none" href="/faqs">
                  FAQs
                </a>
              </li>
              <li className={styles.listItems}>
                <a className="text-white text-decoration-none" href="/blog">
                  Blog
                </a>
              </li>
            </ul>
          </div>
          {/* socials */}
          <div className="col-md-3 col-sm-6">
            <h6 className={styles.headings}>Socials</h6>
            <p className={`text-white ${styles.para}`}>Connect with us on:</p>
            <div className={`container mt-3 ${styles.socials}`}>
              <div
                className={`row align-items-center justify-content-center ${styles.socialIcons}`}
              >
                <div className={`col ${styles.socialsImg}`}>
                  <a
                    className="text-white text-decoration-none"
                    href="https://web.facebook.com/MyngovoFinance/?_rdc=1&_rdr"
                  >
                    <Icon
                      className={styles.Icon}
                      icon="iconoir:facebook"
                      width={30}
                      height={30}
                      color="black"
                    />
                  </a>
                </div>

                <div className={`col ${styles.socialsImg}`}>
                  <a className="text-white text-decoration-none" href="https://x.com/MyNgovo?t=39KcmQ-JbuXiFWnCUw7LkQ&s=09">
                    <Icon
                      className={styles.Icon}
                      icon="pajamas:twitter"
                      width={30}
                      height={30}
                      color="black"
                    />
                  </a>
                </div>
                <div className={`col ${styles.socialsImg}`}>
                  <a
                    className="text-white text-decoration-none"
                    href="https://www.instagram.com/my_ngovo/?hl=en"
                  >
                    <Icon
                      icon="iconoir:instagram"
                      className={styles.Icon}
                      width={30}
                      height={30}
                      color="black"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* copyright */}
        <div
          className={`d-flex justify-content-center align-items-center py-3 border-top border-dark ${styles.copyright}`}
        >
          © {currentYear} Twenifo Technologies Ltd. All rights reserved.
        </div>
      </footer>
    </div>
  );
};

export default Footer;
