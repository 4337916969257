import Accordion from "../../components/Accordion/Accordion";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Question from "../../Pictures/QuestionPic.png";
import styles from "./FAQs.module.css";

export default function FAQs() {
  return (
    <div className={styles.mainContainer}>
      <Navbar />
      <div className={styles.content}>
        {/* heading */}
        <h3 className={styles.h3}>Frequently Asked Questions</h3>
        {/* paragraphs */}
        <div className={styles.divPara}>
          <p className={styles.para}>
            Got more queries ? Lets do something about that.
          </p>

          <p className={styles.para}>
            Here’s a list to common queries from users that you might find
            helpful!
          </p>
        </div>
        {/* image div */}
        <div className={styles.imagContainer}>
          <img
            className={styles.imag}
            src={Question}
            alt="question-illustration"
          />
        </div>
        {/* questions */}
        <div className={styles.accordion}>
          <Accordion />
        </div>
      </div>
      <div className={styles.footer}>
        <Footer />
      </div>
    </div>
  );
}
