import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Contact from "../../Pictures/ContactUs.png";
import Partnership from "../Partnership/Partnership";
import styles from "./ContactUs.module.css";

export default function ContactUs() {
  const ContactUs = "https://forms.gle/ZCHmwTKjKJL9zMSA6";

  const redirectToGoogleForm = () => {
    const googleFormUrl = ContactUs;
    window.location.href = googleFormUrl;
  };
  return (
    <div className={styles.mainContainer}>
      <Navbar />
      <div className={styles.content}>
        {/* image div */}
        <div className={styles.imageDiv}>
          <img src={Contact} alt="contact-us-imag" />
        </div>
        {/* contact us */}
        <div>
          <h4 className={styles.h4}>CONTACT OUR TEAM</h4>
          <p className={styles.p}>
            Reach out to refer your employer and find our your eligibility for
            our salary advance today.
          </p>
        </div>
        {/* partnership */}
        <div>
          <Partnership />
        </div>
        {/* for queries */}
        <p className={styles.queries}>
          For all our queries and info about myNGOVO salary advance, it's simple
        </p>
        <div className="d-flex justify-content-center align-items-center mb-3">
        <button
            className={styles.button}
            onClick={redirectToGoogleForm}
          >
            Contact Us
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
}
