import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import calling from "../../Pictures/calling.png";
import choosing from "../../Pictures/choosing.png";
import styles from "./Employer.module.css";

export default function Employer() {
  const ContactUs = "https://forms.gle/ZCHmwTKjKJL9zMSA6";

  const redirectToGoogleForm = () => {
    const googleFormUrl = ContactUs;
    window.location.href = googleFormUrl;
  };
  return (
    <div className={styles.container}>
      <div>
        <Navbar />
      </div>
      <div className=" p-1">
        <div>
          <p className={styles.p}>
            Provide a salary advance to your employees at no additional cost.
          </p>
          <p className={styles.p}>
            Grant your employees convenient access to salary advances at no
            additional cost.
          </p>
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md">
              <p className={styles.how}>How is that? just</p>
              <button
                onClick={redirectToGoogleForm}
                className={styles.buttonCall}
              >
                Contact Us
              </button>
            </div>
            <div className="col-md">
              <img
                src={calling}
                alt="calling-illustration"
                className={styles.callingImg}
              />
            </div>
          </div>
        </div>

        <h4 className={styles.what}>
          What are the benefits of choosing myNGOVO ?
        </h4>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md">
              <img
                className={styles.callingImg}
                src={choosing}
                alt="choosing-us"
              />
            </div>
            <div className="col-md">
              <ul className={styles.ul}>
                <li>Enhance employee wellness.</li>
                <li>
                  Employees enjoy benefits without additional expenses, ensuring
                  no disruption to your payroll.
                </li>
                <li>No financial commitment required.</li>
                <li>No setup or maintenance costs for your organization.</li>
                <li>
                  Quick processing, with disbursement possible within 24 hours
                  from the initial message.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <h4 className={styles.h4}>What you gain in return</h4>
        <div className={styles.gainContainer}>
          <ol>
            <li>Improve employee performance</li>
            <li>Retain workers</li>
            <li>No cashflow strains</li>
          </ol>
          <button
            onClick={redirectToGoogleForm}
            className={`mb-4 ${styles.advance}`}
          >
            Get Salary Advance
          </button>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}
