import React from "react";
import logo from "../../Pictures/logo.png";
import styles from "./Navbar.module.css";
import { Link } from "react-router-dom";

export default function Navbar() {
  const ReferEmployee = "https://forms.gle/ZCHmwTKjKJL9zMSA6";

  const redirectToGoogleForm = () => {
    const googleFormUrl = ReferEmployee;
    window.location.href = googleFormUrl;
  };

  return (
    <nav
      class={`navbar navbar-expand-lg bg-body-tertiary border-bottom border-dark-subtle border w-100 ${styles.navbar}`}
    >
      <div class="container-fluid">
        <div className={styles.imageDiv}>
          <Link className="navbar-brand" to="/">
            <img
              src={logo}
              alt="companyLogo"
              width={150}
              className={styles.imageCon}
            />
          </Link>
        </div>
        <button
          className={`navbar-toggler ${styles.greenMenuIcon}`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon text-color-info"></span>
        </button>
        <div
          class={`collapse navbar-collapse ${styles.navbarCollapse}`}
          id="navbarSupportedContent"
        >
          <ul
            class={`navbar-nav me-auto mb-2 mb-lg-0 grid gap-4 row-gap-3  ${styles.navbarCollapse}`}
          >
            <li class="nav-item">
              <Link
                className={`nav-link active text-decoration-none ${styles.home}`}
                aria-current="page"
                to="/"
              >
                Home
              </Link>
            </li>

            <li class={`nav-item dropdown`}>
              <a
                class="nav-link dropdown-toggle text-decoration-none"
                href="/"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Resources
              </a>
              <ul class={`dropdown-menu ${styles.dropdownMenu}`}>
                <li>
                  <Link
                    className="dropdown-item text-decoration-none"
                    to="/blog"
                  >
                    Blog
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <Link
                    className="dropdown-item text-decoration-none"
                    to="/contact"
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </li>

            <li className="nav-item">
              <Link className="nav-link text-decoration-none" to="/employer">
                Employer
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-decoration-none" to="/employee">
                Employee
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-decoration-none" to="/faqs">
                FAQs
              </Link>
            </li>
               <button
                onClick={redirectToGoogleForm}
                className={styles.button}
              >
                Refer Employer
              </button>
          </ul>
        </div>
      </div>
    </nav>
  );
}
