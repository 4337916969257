import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import styles from "./HomePage.module.css";
import Overlay from "../../components/overlay/overlay";
import Carousel from "./Carousel/Carousel";
import Section2 from "./Section2/sec2";
import Section3 from "./Section3/Section3";
import { Icon } from "@iconify/react";

export default function Home() {
  const [showButton, setShowButton] = useState(false);

  const ContactUs = "https://forms.gle/ZCHmwTKjKJL9zMSA6";
  const ReferEmployee = "https://forms.gle/ZCHmwTKjKJL9zMSA6";


  // Function to handle scrolling to the top of the page
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Function to toggle the visibility of the button based on scroll position
  const handleScroll = () => {
    if (window.pageYOffset > 100) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  // Add event listener to handle scrolling
  window.addEventListener("scroll", handleScroll);

  return (
    <div className={styles.homeContainer}>
      <div className={` `}>
        <Navbar />
      </div>

      <div className="">
        {/* Homepage Content */}
        <section className={styles.containerImg}>
          <Carousel />
          <div className={styles.content}>
            <h6
              className={`mb-4 fs-1 animate__animated animate__bounce ${styles.h6}`}
            >
              SALARY ADVANCE, IN YOUR HANDS , EVERYDAY.
            </h6>
            <p className={styles.paragraph1}>
            myNGOVO helps employees
              <br /> access a portion of their <br />
              salary on demand
              <br />
              through The App
            </p>
            <p className={styles.paragraph2}>
              It's not a loan, just your earned salary, whenever you need it.
            </p>
            <div className={styles.linkDiv}>
              <Link
                className={`p-2  text-decoration-none ${styles.LinkContact}`}
                to={ContactUs}
              >
                Contact Us
              </Link>
              

              <Link
                className={`p-2 text-decoration-none  ${styles.LinkRefer}`}
                to={ReferEmployee}
              >
                Refer Employer{" "}
              </Link>
            </div>
          </div>
        </section>

        <section>
          <Section2 />
        </section>

        <section>
          <Section3 />
        </section>

        <section>
          <Overlay />
        </section>

        {/* Footer */}
        <Footer className={styles.footer} />

        {showButton && (
          <button className={styles.returnToTopButton} onClick={scrollToTop}>
            <Icon
              icon="memory:arrow-up"
              className={styles.Icon}
              width={30}
              height={30}
              color="white"
            />
          </button>
        )}
      </div>
    </div>
  );
}
