import partner from "../../Pictures/partner.png";
import { Icon } from "@iconify/react";
import styles from "./Partnership.module.css";

export default function Partnership() {
  return (
    <>
      <h4 className={styles.h4}>
        <span className={styles.span}>
          <Icon
            icon="mdi:partnership-outline"
            width={24}
            height={24}
            color="#EA5D26"
          />
        </span>
        Partner with us
      </h4>
      <div className={styles.imageDiv}>
        <img
          className={styles.partnerImg}
          src={partner}
          alt="partner with us imag"
        />
      </div>

      <div className={styles.partnership}>
        <p className={styles.p}>
          For partnership, you can reach us through{" "}
          <a className={styles.link} href="mailto:info(at)myngovo.co.ke">
            info@myngovo.co.ke
          </a>
        </p>
      </div>
    </>
  );
}
