import React from "react";
import foot from "../../Pictures/footpic.png";
import styles from "./Overlay.module.css";

export default function Overlay() {
  const ContactUs = "https://forms.gle/ZCHmwTKjKJL9zMSA6";

  const redirectToGoogleForm = () => {
    const googleFormUrl = ContactUs;
    window.location.href = googleFormUrl;
  };
  return (
    <div className={styles.overlayContainer}>
      <div className={styles.overlayContent}>
        <div className={styles.overlayImageContainer}>
          <img src={foot} alt="school-bag" className={styles.overlayImage} />
          <div className={styles.overlay}></div>
        </div>
        <div className={styles.overlayText}>
          <p className="text-white mt-4 mb-3 text-center">
            We have built a workspace with a difference that gives employees the
            power to access the salary advance anytime with no disruption to the
            payroll systems.
          </p>
          <button onClick={redirectToGoogleForm} className="mb-4">
            Get Salary Advance
          </button>
        </div>
      </div>
    </div>
  );
}
