import styles from "./Section3.module.css";

export default function Section3() {
  return (
    <div className={`containermt-5 mb-5 mt-5 ${styles.section}`}>
      <div className="d-flex justify-content-center align-items-center flex-column">
        <h5 className={styles.h5}>Employer Benefit.</h5>
        <h2 className="fw-bold">At no extra cost to you.</h2>
      </div>

      {/* columns */}
      <div className="container text-center">
        <div className={`row mt-3 gap-5 ${styles.rowColumns}`}>
          <div className={`col ${styles.benefits}`}>
            <span className={styles.numbers}>1</span>
            <p className={styles.para}>
              You attract and retain your employees.
            </p>
          </div>
          <div className={`col ${styles.benefits}`}>
            <span className={styles.numbers}>2</span>
            <p className={styles.para}>There is zero cost to your business.</p>
          </div>
          <div className={`col ${styles.benefits}`}>
            <span className={styles.numbers}>3</span>
            <p className={styles.para}>
              No burden on payroll for salary advance requests.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
