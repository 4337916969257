import React from "react";
import youngFam from "../../../Pictures/young-fam.jpeg";
import styles from "./Sec2.module.css";
import "animate.css";

export default function Section2() {
  return (
    <div className={styles.container}>
      <div className={`row mb-4 ${styles.row}`}>
        <div className={`col-md-6 ${styles.column1}`}>
          <p className={`fw-bold mt-2 mb-4 ${styles.support}`}>
            SUPPORT YOUR EMPLOYEES WITH LOVE
          </p>
          <h2 className="text-black fw-bold m-1">
            What is myNGOVO salary advance ?
          </h2>
          <p className={`mt-2 mb-3 ${styles.explanation} `}>
            Give your employees access to have salaries they have earned
            whenever they need it. Support them in their emergencies and reduce
            employee struggle rate.
          </p>
        <div className=" mt-2 d-flex justify-content-center align-item-center">
          <h4 className={` animate__rubberBand ${styles.explore} `}>
            Explore More
          </h4>
        </div>
        </div>

        <div className={`col-md-6 mt-5 ${styles.column2}`}>
          {/* <div className={styles.imageSquare}></div> */}
          <img src={youngFam} alt="happy-family" className="img-fluid" />
        </div>
      </div>
    </div>
  );
}
