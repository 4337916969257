import { useState } from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Pic from "../../Pictures/myNgovo.jpg";
import blogContent from "../../blogContent.json";
import styles from "./Blog.module.css"; // Import CSS module

// Function to truncate text to first 200 words
const truncateText = (text) => {
  const words = text.split(" ");
  if (words.length > 80) {
    return words.slice(0, 80).join(" ") + "...";
  }
  return text;
};

export default function Blog() {
  const [expandedSections, setExpandedSections] = useState(
    new Array(blogContent.length).fill(false)
  );

  const handleExpand = (index) => {
    setExpandedSections((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  return (
    <div className={styles.mainContainer}>
      <div className="">
        <Navbar />
      </div>

      {/* card */}
      <div className={`row ${styles.blogContainer}`}>
        {/* card */}
        {blogContent.map((section, index) => (
          <div key={index} className={`col ${styles.cardWrapper}`}>
            <div className={`card mb-3 mt-3 ${styles.card}`}>
              <img src={Pic} className="card-img-top" alt="myNgovo-imag" />
              <div className="card-body">
                <h5
                  className={`card-title fw-bold text-decoration-none ${styles.heading}`}
                >
                  {section.heading}
                </h5>
                <p className="card-text text-black">
                  {expandedSections[index]
                    ? section.text
                    : truncateText(section.text)}
                </p>
                {!expandedSections[index] && (
                  <button
                    className={`btn btn text-decoration-none ${styles.button}`}
                    onClick={() => handleExpand(index)}
                  >
                    Read More . . .
                  </button>
                )}
                {expandedSections[index] && (
                  <button
                    className={`btn btn text-decoration-none ${styles.buttonCollapse}`}
                    onClick={() => handleExpand(index)}
                  >
                    Collapse
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="">
        <Footer />
      </div>
    </div>
  );
}
