import styles from "./Carousel.module.css";
import carouselImag from "../../../Pictures/carousel-2.jpeg";

export default function Carousel() {
  return (
    <div className={styles.Container}>
      <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-inner">
          {/* carousel 1 */}
          <div className="carousel-item active">
            <div className={styles.ImageContainer}>
              <img src={carouselImag} className="d-block " alt="..." />
            </div>
          </div>
          {/* carousel 2 */}
          <div className="carousel-item">
            <div className={styles.ImageContainer}>
              <img src={carouselImag} className="d-block " alt="..." />
            </div>
          </div>
          {/* carousel 3 */}
          <div className="carousel-item">
            <div className={styles.ImageContainer}>
              <img src={carouselImag} className="d-block " alt="..." />
            </div>
          </div>
        </div>

        {/*  prev*/}
        {/* <button
          className={`carousel-control-prev `}
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span className="visually-hidden">Previous</span>
        </button> */}
        {/* next */}
        <button
          className={`carousel-control-next ${styles.btnNext} `}
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
          aria-label="Next"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            fill="currentColor"
            className={`bi bi-arrow-right ${styles.carouselIndicatorsButton}`}
            viewBox="0 0 16 16"
            style={{ padding: "10px", fontWeight: "bold" }}
          >
            <path
              fillRule="evenodd"
              d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}
