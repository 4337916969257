import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import styles from "./Employee.module.css";
import EmpolyeePic from "../../Pictures/employee-pic.png";
import Paid from "../../Pictures/getPaid.png";

export default function Employee() {

  const ContactUs = "https://forms.gle/ZCHmwTKjKJL9zMSA6";

  const redirectToGoogleForm = () => {
    const googleFormUrl = ContactUs;
    window.location.href = googleFormUrl;
  };

  
  return (
    <div className={styles.container}>
      {/* navbar */}
      <div className="w-100">
        <Navbar />
      </div>
      {/* content */}
      <div className={`p-3 ${styles.container}`}>
        <div class="container">
          <div class="row">
            <div class={`col-md-6 ${styles.secContainer}`}>
              <p className={styles.p}>Get up to 50% of your salary today!</p>
              <p className={styles.p}>
                Get salary advance today from a mobile app. No CRB checks!
              </p>
              <button
                onClick={redirectToGoogleForm}
                className={styles.button}
              >
                Refer Employer
              </button>
            </div>
            <div class="col-md-6">
              <img
                src={EmpolyeePic}
                alt="employee-dummy-pic"
                className="img-fluid"
              />
            </div>
          </div>
        </div>

        {/* sec 2 */}
        <div className="">
          <h4 className={styles.h4}>Avoid costly mobile loans.</h4>
          <div class="container">
            <div class="row">
              <div class={`col ${styles.secContainer}`}>
                <p className={styles.p}>
                  Let your money work for you. Get access to the salary you have
                  already earned when you need it.
                </p>
                <ul>
                  <li> Get paid today.</li>
                  <li> No interest.</li>
                  <li> No CRB checks.</li>
                  <li> No calls from us.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* image */}
        <div className={styles.imageHolder}>
          <img src={Paid} alt="paid-illustration" className={styles.img} />
        </div>
        <div className={styles.containerB}>
          <p className={`mt-5 ${styles.p}`}>
            You don't have to wait until the end of the month. You also don't
            have to compromise your data online.
          </p>
          <button
            onClick={redirectToGoogleForm}
            className={`mb-4 ${styles.advance}`}
          >
            Get Salary Advance
          </button>        </div>
      </div>
      {/* footer */}
      <div>
        <Footer />
      </div>
    </div>
  );
}
