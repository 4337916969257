import React from "react";
import { Route, Routes, Navigate } from "react-router";
import "./App.css"
import Home from "./pages/HomePage/HomePage";
import Blog from "./pages/Blog/Blog";
import ContactUs from "./pages/Contact-Us/ContactUs";
import Employer from "./pages/Employer/Employer";
import Employee from "./pages/Employee/Employee";
import FAQs from "./pages/FAQs/FAQs";

function App() {
  return (
    <div className="">
      <Routes>
        <Route element={<Home />} path="/" />
        <Route element={<Blog />} path="/blog" />
        <Route element={<ContactUs />} path="/contact" />
        <Route element={<Employer />} path="/employer" />
        <Route element={<Employee />} path="/employee" />
        <Route element={<FAQs />} path="/faqs" />
        {/* Add a default route or redirect to handle unknown routes */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>{" "}
    </div>
  );
}

export default App;
