import React from "react";
import styles from "./Accordion.module.css";

const questions = [
  {
    heading: "Employee",
    items: [
      {
        question: "How does myNGOVO work?",
        answer:
          "myNGOVO gives you access to part of your salary as an advance payment. You can access your salary advance anytime and any day within the pay period."
      },
      {
        question: "Is this a loan?",
        answer:
          "No. myNGOVO is not a loan. You get access to your salary not just at the end of the month."
      },
      {
        question: "How do I access my salary advance?",
        answer:
          "Your employer needs to be registered with myNGOVO. Refer your employer or send us an email with company details and who to contact by filling in the contact form. After registration you can access one-click salary advance with myNGOVO with no interest charges on our mobile app."
      },
      {
        question: "How fast will the money be remitted?",
        answer:
          "You will normally receive your money instantly or within five minutes in your mobile money account and if you are experiencing issues send us an email or call us."
      },
      {
        question: "How do I pay myNGOVO back?",
        answer:
          "The amount you access is deducted from your salary during payroll processing and you can also pay back from the Repay tab in the app."
      }
    ]
  },
  {
    heading: "Employer/Company",
    items: [
      {
        question: "What does it cost to offer myNGOVO to my employees?",
        answer:
          "There is no cost to you. Absolutely none. No capital commitment or risk as myNGOVO is offered as a financial wellness benefit to the employees."
      },
      {
        question: "Can myNGOVO impact our company?",
        answer:
          "Yes. Happy employees. Low employee turnover. Increased productivity. Free cash flow."
      },
      {
        question: "What happens if an employee leaves the company?",
        answer:
          "Workers can only access up to 50% of their salaries which is determined by the number of days worked. Hence, an employee cannot access advance payment for days not worked. Once they leave, blacklist and delete their contact and they cannot access funds further through myNGOVO."
      },
      {
        question: "Is it difficult to setup?",
        answer:
          "No, it's not difficult to set up. myNGOVO provides a workspace with a difference, allowing employees to access salary advances with ease and without disruption to payroll systems."
      }
    ]
  }
];

export default function Accordion() {
  return (
    <div  className={styles.accordion}>
      {questions.map((section, index) => (
        <div key={index}>
          <h3 className={styles.sectionHeading}>{section.heading}</h3>
          {section.items.map((item, idx) => (
            <div className={styles.accordionItem} key={idx}>
              <div className="accordion">
                <div className="accordion-item">
                  <h2 className="accordion-header ">
                    <button
                      className={`${styles.accordionButton} accordion-button collapsed `}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#accordionCollapse-${index}-${idx}`}
                      aria-expanded="false"
                      aria-controls={`accordionCollapse-${index}-${idx}`}
                    >
                      <span className={styles.plusIcon}>+</span>
                      {item.question}
                    </button>
                  </h2>
                  <div
                    id={`accordionCollapse-${index}-${idx}`}
                    className={`${styles.accordionBody} accordion-collapse collapse`}
                    data-bs-parent={`#accordion-${index}`}
                  >
                    <div className="accordion-body">{item.answer}</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
